import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../../util/helpers";
import ButtonLink from "../../ButtonLink";

const ExperienceFinished = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section className="component-experience-finished bg-white text-black py-11 xl:pt-52 xl:pb-36" ref={sectionEl}>

      <div className="max-w-1440px mx-auto px-8 xl:px-0">
        <h2 className="font-normal font-heading text-5xl xl:text-120px leading-extratight mb-6">{props.data.title}</h2>
        <p className="italic text-lg leading-close xl:text-5xl xl:leading-tight mb-11 xl:mb-52 mx-auto max-w-5xl xl:px-11">{props.data.description}</p>
      </div>

      <div className="pb-12 xl:pb-30">
        <div className="mb-2 xl:mb-6 grid grid-cols-1 xl:grid-cols-collage-row-1 justify-between gap-y-2 xl:gap-y-0">
          <div
            className="h-[44vw] xl:h-[506px]"
            style={{
              background: `url(${props.data.images[0][0]}) center / cover no-repeat`,
            }}
          />
          <div
            className="h-[67vw] xl:h-[506px]"
            style={{
              background: `url(${props.data.images[1][0]}) center / cover no-repeat`,
            }}
          />
        </div>
        <div className="mb-2 xl:mb-6 grid grid-cols-1 xl:grid-cols-collage-row-2 justify-between gap-y-2 xl:gap-y-0">
          <div
            className="h-[67vw] xl:h-[506px]"
            style={{
              background: `url(${props.data.images[2][0]}) center / cover no-repeat`,
            }}
          />
          <div
            className="h-[44vw] xl:h-[506px]"
            style={{
              background: `url(${props.data.images[3][0]}) center / cover no-repeat`,
            }}
          />
        </div>
        <div className="mb-2 xl:mb-6 flex flex-wrap xl:flex-nowrap xl:grid xl:grid-cols-collage-row-3 justify-between gap-y-2 xl:gap-y-0">
          <div
            className="w-full xl:w-auto h-[67vw] xl:h-[506px]"
            style={{
              background: `url(${props.data.images[4][0]}) center / cover no-repeat`,
            }}
          />
          <div
            className="w-[39vw] h-[44vw] xl:w-auto xl:h-[506px]"
            style={{
              background: `url(${props.data.images[5][0]}) center / cover no-repeat`,
            }}
          />
          <div
            className="w-[59vw] h-[44vw] xl:w-auto xl:h-[506px]"
            style={{
              background: `url(${props.data.images[6][0]}) center / cover no-repeat`,
            }}
          />
        </div>
      </div>

      <div className="max-w-4xl px-8 mx-auto">
        <p className="text-xl leading-[1.8] mb-6">{props.data.cta.title}</p>
        <ButtonLink
          color="black" 
          link={props.data.cta.button.url}
          title={props.data.cta.button.title}
        />
      </div>

    </section>
  );
}

export default ExperienceFinished;
