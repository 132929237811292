import LoadingLink from "../../LoadingLink";
import Icon, { RightArrow } from "../../Icons";

const Remaining = (props: any) => {
  const remainingCaseStudies = props && props.data && props.data.length > 0 && props.data.map( (project: any, index: any) => 
    (
      <div key={project.ID} className="text-left py-6 border-b border-grey group xl:hover:px-7 relative overflow-hidden transition-all duration-700">
        <div className="w-full h-full absolute top-0 -left-full bg-orange py-6 px-7 transition-all duration-700 xl:group-hover:left-0" />
        <div className="relative z-2 xl:grid xl:grid-cols-project xl:gap-[8%]">
          <strong className="block font-normal font-heading text-4xl mb-4 xl:mb-0 xl:text-6xl leading-extratight max-w-480px transition-all duration-700 xl:group-hover:text-black">{project.post_title}</strong>
          <div className="max-w-2xl">
            <p dangerouslySetInnerHTML={{ __html: project.short_description }} className="font-normal font-heading text-sm leading-6 xl:text-base text-white mb-6 xl:mb-3 transition-all duration-700 xl:group-hover:text-black" />
            <p className="text-xs leading-normal xl:text-sm xl:leading-5 mb-6 xl:mb-8 transition-all duration-700 xl:group-hover:text-black"><em>{project.services}</em></p>
            <LoadingLink link={'/work/'+project.post_name} className="text-base leading-none flex gap-3.5 transition-all duration-700 xl:group-hover:text-black">
              <Icon className="w-[30px] h-4 xl:group-hover:hidden" type={<RightArrow color="#ff6e56" />} />
              <Icon className="w-[30px] h-4 hidden xl:group-hover:inline-block" type={<RightArrow color="#000000" />} />
              <span>See the project</span>
            </LoadingLink>
          </div>
        </div>
      </div>
    )
  );

  return (
    <div className="case-studies-list__remaining mt-20 xl:mt-0 xl:pt-37.5 mb-12.5 xl:mb-15 border-t border-grey xl:border-none">
      {remainingCaseStudies}
    </div>
  )
}

export default Remaining;