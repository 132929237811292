import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../util/helpers";

const Services = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const services = props.data && props.data.services && props.data.services.length > 0 && props.data.services.map( (service: any, index: any) => 
    (
      <div key={index} className="services__service xl:grid xl:justify-between xl:items-start xl:gap-17 border-b xl:border-b-2 border-grey py-6">
        <strong className="block font-normal font-heading uppercase text-4xl xl:text-6xl leading-extratight">{service.title}</strong>
        <div dangerouslySetInnerHTML={{ __html: service.description }} className="text-base font-normal font-heading" />
      </div>
    )
  );

  return (
    <section className="component--services pt-40 pb-48 xl:pt-64 xl:pb-[305px]" ref={sectionEl}>
      <div className="max-w-1440px mx-auto xl:pl-[220px] xl:pr-40 text-left px-8 xl:px-0">
        <h2 className="font-normal font-heading text-2xl leading-extratight xl:text-4xl border-b xl:border-b-2 border-grey pb-5">{props.data.title}</h2>
      </div>
      <div className="max-w-1440px mx-auto xl:pl-[220px] xl:pr-40 text-left px-8 xl:px-0">
        {services}
      </div>
    </section>
  );
}

export default Services;
