import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../util/helpers";

const LeftImageWithText = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section className="component--left-image-with-text pt-17 pb-25" ref={sectionEl}>
      <div className="max-w-1440px mx-auto xl:flex">
        <div dangerouslySetInnerHTML={{ __html: props.data.text }} className="left-image-with-text__text block xl:hidden text-left text-2xl leading-tight px-8" />
        <img className="w-full xl:max-w-[747px] mt-14 xl:mt-0" src={props.data.image.url} alt="" width={747} />
        <div className="flex items-center">
          <div dangerouslySetInnerHTML={{ __html: props.data.text }} className="hidden xl:block left-image-with-text__text pl-44 pr-40 text-left text-2xl leading-normal" />
        </div>
      </div>
    </section>
  );
}

export default LeftImageWithText;
