// import { NavLink, Link } from "react-router-dom";
import LoadingLink from "./LoadingLink";

const Footer = (props: any) => {
  const footer = props.data.footer;

  const menuItems = footer && footer.menu && footer.menu.length > 0 && footer.menu.map( (menuItem: any, index: any) => 
    (
      <li key={index} className="lg:w-[474px] mb-2.5 last:mb-0">
        <LoadingLink
          link={'/'+menuItem.slug}
          className={'menu-link flex text-white text-sm leading-extratight rounded-20px border border-white p-2.5 w-full h-10 justify-center items-center cursor-pointer relative overflow-hidden transition-all duration-500 hover:border-orange ' + menuItem.classes.toString().replaceAll(',', ' ')}
        >
          <span className="menu-link__bg block w-0 h-full bg-orange absolute left-0 transition-all duration-500" />
          <span className="z-2">{ menuItem.title}</span>
        </LoadingLink>
      </li>
    )
  );

  return (
    <footer className="site-footer bg-black text-white text-left py-7 xl:py-17 font-normal">
      <div className="w-full lg:max-w-[540px] xl:max-w-1440px mx-auto px-8 xl:px-0">
        <div className="w-full mb-11">
          <LoadingLink
            link="/"
            className="w-25 xl:w-64.5 h-auto inline-block hover:opacity-80"
          >
            <img className="w-auto xl:h-full" src={footer.logo[0]} alt="" />
          </LoadingLink>
        </div>
        <div className="xl:flex xl:justify-between xl:pl-29">
          <div>
            <strong className="block font-normal font-heading text-lg xl:text-4xl mb-4 leading-close">{footer.contact_heading}</strong>
            <div className="flex gap-18.5 text-sm leading-6 xl:text-base">
              <span>
                t. <a href={'tel:'+footer.phone}>{footer.phone}</a><br />
                e. <a className="underline hover:no-underline" href={'mailto:'+footer.email}>{footer.email}</a>
              </span>
              <span className="block max-w-[100px]" dangerouslySetInnerHTML={{ __html: footer.location }} />
            </div>
            <p className="hidden xl:block text-xxs leading-4 mt-4">&copy; {props.data.site_name} { new Date().getFullYear() }. All Rights Reserved.</p>
          </div>
          <nav className="font-heading mt-16 mb-17 xl:mt-0 xl:mb-0">
            <ul>
              {menuItems}
            </ul>
          </nav>
          <p className="block lg:text-center xl:text-left xl:hidden text-xxs leading-4">&copy; {props.data.site_name} { new Date().getFullYear() }. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;