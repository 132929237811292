import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../../util/helpers";
import Featured from "./Featured";
import Remaining from "./Remaining";

const CaseStudiesList = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    const oddElements = [];
    const evenElements = [];

    for (let i = 0; i < props.data.featured_case_studies.length; i++) {
      if (i % 2 === 0) {
        oddElements.push(props.data.featured_case_studies[i]);
      } else {
        evenElements.push(props.data.featured_case_studies[i]);
      }
    }

    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [props.data]);

  return (
    <section className="component--case-studies-list pt-15 xl:pt-37.5" ref={sectionEl}>
      <div className="max-w-1440px mx-auto px-8 xl:px-0">

        {
          props.data && 
          props.data.featured_case_studies && 
          props.data.remaining_case_studies &&
          <>
            <Featured data={props.data.featured_case_studies} />
            <Remaining data={props.data.remaining_case_studies} />
          </>
        }

        <p className="text-white font-normal font-heading text-base xl:text-2xl xl:leading-none mb-32 xl:mb-37.5">Check out a little extra below</p>

      </div>
    </section>
  );
}

export default CaseStudiesList;
