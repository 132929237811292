import { useEffect, useRef, useState } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../util/helpers";
import TextRise from "../TextRise";

const StatsRiseUp = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const stats = props.data && props.data.stats && props.data.stats.length > 0 && props.data.stats.map( (stat: any, index: any) => 
    (
      <div key={index} className={'stats__card relative mb-20 xl:mb-0'+(index === 1 ? ' xl:w-[450px]' : '')}>
        <strong className="block font-normal font-heading text-90px leading-extratight xl:text-[9rem] mt-2.5 mb-5">
          <TextRise>
            {stat.count_formatted}
            {/* <span className="stat__count">{stat.count_formatted}</span> */}
          </TextRise>
        </strong>
        <em className="block text-2xl leading-normal xl:text-3xl xl:leading-none mb-0.5">{stat.title}</em>
        <span className="block text-lightgrey text-xs xl:text-base italic">{stat.subtitle}</span>
      </div>
    )
  );

  return (
    <section className="component--stats pt-20 pb-30 xl:pt-40 xl:pb-80" ref={sectionEl}>
      <div className="max-w-1440px mx-auto text-orange px-8 xl:px-0">
        <h2 className="block font-heading text-2xl leading-9 xl:text-3xl xl:leading-laidback mb-8" dangerouslySetInnerHTML={{ __html: props.data.title }} />
        <div className="xl:flex xl:gap-72">
          {stats}
        </div>
      </div>
    </section>
  );
}

export default StatsRiseUp;
