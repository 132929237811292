import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../../util/helpers";

const ExperienceInitial = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section className="component-experience-initial" ref={sectionEl}>
      <div className="max-w-1440px mx-auto text-orange text-left mb-20 xl:mb-72 pb-4 px-8 xl:px-0">
        <h2 dangerouslySetInnerHTML={{ __html: props.data.title }} className="experience--initial__heading font-normal font-heading text-5xl leading-[1.1667] xl:text-120px xl:leading-extratight mb-6 xl:mb-20 max-w-6xl pr-4" />
        <div className="xl:grid xl:grid-cols-2 xl:gap-17 mb-20 xl:mb-72">
          <p className="italic text-2xl xl:text-5xl leading-tight max-w-[662px]">{props.data.subtitle}</p>
          <div dangerouslySetInnerHTML={{ __html: props.data.description }} className="font-medium text-sm leading-6 xl:text-lg xl:leading-7 xl:max-w-md mt-20 xl:mt-40" />
        </div>
        <div className="max-w-2xl pt-4 xl:px-1.5 pb-3 mx-auto text-center mb-20">
          <h3 className="italic text-5xl xl:text-7xl mb-7">{props.data.blueprinting.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: props.data.blueprinting.description }} className="xl:px-4 text-sm leading-6 xl:text-lg" />
        </div>
        {
          props.data.blueprinting.image && 
          props.data.blueprinting.image.url && 
          <img src={props.data.blueprinting.image.url} alt="" className="hidden xl:block max-w-full mx-auto" width={1296} />
        }
        {
          props.data.blueprinting.image_mobile && 
          props.data.blueprinting.image_mobile.url && 
          <img src={props.data.blueprinting.image_mobile.url} alt="" className="xl:hidden w-[63vw] max-w-full mx-auto" />
        }
      </div>
    </section>
  );
}

export default ExperienceInitial;
