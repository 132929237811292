import { useEffect, useRef } from "react";
import {
  onLoadScrollHandler,
  scrollHandler as globalScrollHandler
} from "../../util/helpers";

import Icon, { Arrow, Sparkle } from "../Icons";
// import { Link } from "react-router-dom";
import LoadingLink from "../LoadingLink";

// const siteUrl = process.env.REACT_APP_PUBLIC_URL;

const HeroSection = (props: any) => {
  const sectionEl = useRef<HTMLElement>(null);

  useEffect(() => {
    onLoadScrollHandler(sectionEl);// add 'on-screen' class on load if section in view

    // add 'on-screen' class when user scrolls to section
    const scrollHandler = () => { return globalScrollHandler(sectionEl) };

    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <section className="component--hero-section pt-8 pb-9 xl:pt-20 xl:pb-37.5" ref={sectionEl}>
      <h1 className="site-heading leading-none text-orange font-heading text-left px-8 xl:px-0 xl:whitespace-nowrap xl:text-center">{props.data.title}</h1>
      <div className="max-w-1440px mx-auto px-8 min1440px:px-0">
        <strong className="font-medium italic text-lg -mt-2.5 pr-12.5 text-orange text-right hidden xl:block">{'('+props.data.subtitle+')'}</strong>
        <div className="pt-1 xl:pt-7.5 grid grid-cols-1 gap-7 xl:grid-cols-2 xl:flex xl:gap-11 text-orange text-left">
          <p className="animate fade-in-grow ease-in-out delay-1000 font-heading text-2xl xl:text-5xl leading-tight xl:max-w-480px">{props.data.description}</p>
          <div>
            <p className="animate fade-in-grow ease-in-out delay-1000 text-sm xl:text-lg leading-breezy pt-1 xl:max-w-350px">
              {props.data.copy}
            </p>
            <div className="animate slide-from-left ease-in-out delay-1750 flex mt-8 xl:mt-24 items-center">
              <Icon className="w-9 h-9 xl:w-14 xl:h-14" type={<Sparkle />} />
              <span className="font-heading text-xl uppercase inline-block text-white leading-8 ml-3 xl:ml-4 xl:leading-none xl:text-2xl">
                Discover&nbsp;
                <LoadingLink
                  className="underline hover:no-underline"
                  // to={ props.data.discover_link.url.replace(siteUrl, '').replace(/\/$/, '') }
                  link={props.data.discover_link.url}
                >
                  {props.data.discover_link.title}
                </LoadingLink>
              </span>
            </div>
          </div>
          <Icon className="animate slide-from-top ease-in-out delay-1750 w-30 h-30 self-end ml-48 hidden xl:inline-block" type={<Arrow />} />
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
